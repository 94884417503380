<template>
  <div class="fieldManagement app-container">
    <base-table :api="api" :option="option" :resetMergeData="resetMergeData">
      <template #menu="{ row }">
        <DisableBtn v-if="permission.enable" class="menu-btn-item" :row="row" :init="init"></DisableBtn>
        <EditBtn v-if="permission.edit" class="menu-btn-item" :row="row" :init="init"></EditBtn>
      </template>
    </base-table>
  </div>
</template>

<script>
import { option } from './option'
import baseTableMixin from '@/components/base/baseTable/mixins/baseTable'
import fieldManagementApi from '@/api/system/fieldManagementApi'
import DisableBtn from '@/views/system/fieldManagement/mudule/DisableBtn'
import EditBtn from './mudule/EditBtn/index'
import { checkPermission } from '@/utils'
export default {
  name: 'fieldManagement',
  props: {},
  mixins: [baseTableMixin],
  components: {
    DisableBtn,
    EditBtn
  },
  data() {
    this.api = fieldManagementApi.list
    return {
      option,
      resetMergeData: {
        templateUsageType: 1
      }
    }
  },
  computed: {
    permission() {
      return {
        edit: checkPermission('externaladmin:system:fieldManagement:edit'),
        enable: checkPermission('externaladmin:system:fieldManagement:enable')
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
